.main-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}

.logo-container {
  width: 100%;
  margin-bottom: 20px;
  display: inline-block;
  background-color: rgb(6, 6, 250);
}

.background-logo {
  width: 70vw;
  height: 20vh;
  color: white;
  font-size: 2.5rem;
  font-weight: 700;
  font-style: italic;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
  display: inline-block;
  margin-bottom: 50px;
  text-align: center;
  position: absolute;
  padding-left: 50px;
}

.product-logo {
  width: 250px;
  height: 200px;
}

.KshirText {
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  color: black;
  background-color: cyan;
}

.product-container {
  display: flex;
  margin: 5px;
  justify-content: center;
}

.image-container {
  text-align: center;
  flex: 1;
}

.text-container {
  flex: 1;
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  align-content: center;
  color: black;
  padding: 30px;
  border-style: outset;
}

.scrollImage {
  width: 700px;
  height: 400px;
  object-fit: contain;
  border-style: outset;
}

.locationGraphics {
  width : 450px;
  height: 250px;
}

@media only screen and (max-width: 1000px) {
  .background-logo {
    height: 6rem;
    position: relative;
    font-size: 1.8rem;
    padding: inherit;
  }

  .logo-container {
    display: inline-flex;
    background-color: rgb(6, 6, 250);
  }

  .product-container {
    display: flex;
    margin: 5px;
    justify-content: center;
    display: block;
  }

  .scrollImage {
    width: 700px;
    height: 400px;
    object-fit: contain;
    border-style: outset;
    display: table-cell;
  }

  .locationGraphics {
    width : 70vw;
    height: 35vh;
  }

  .image-container {
    position: relative;
    text-align: center;
    flex: 1;
  }
}